import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'

import Buefy from 'buefy'
import VueQuillEditor from 'vue-quill-editor'
import ReadMore from 'vue-read-more';
 
import * as Emoji from "quill-emoji";
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import "quill-emoji/dist/quill-emoji.css";
import "quill-mention";

var atValues = JSON.parse(localStorage.getItem('members'));

atValues = (atValues) ? atValues.records : [];

Vue.use(VueQuillEditor);
Vue.use(Buefy);

import 'buefy/dist/buefy.css'
import VueRouter from 'vue-router';

import '@mdi/font/css/materialdesignicons.css'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';

Vue.use(Vuesax, {
    colors: {
      primary:'#5b3cc4',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(36, 33, 69)'
    }
  })

Vue.use(VueRouter);

var dev_type = process.env.VUE_APP_Enviroment;
let uri = window.location.search.substring(1); 
let params = new URLSearchParams(uri);

if(params.has('origin')){
  localStorage.setItem('base_url',params.get('origin'));
  Vue.prototype.$apiLink = params.get('origin');
}else{
  Vue.prototype.$apiLink = (dev_type == 'local') ? process.env.VUE_APP_Local_URL : process.env.VUE_APP_Live_URL;
}

Vue.config.productionTip = false

import router from './router'
import store from './store'

Vue.use(ReadMore);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
